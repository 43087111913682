import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import ReactModal from "react-modal";
import LinkAsModal, { ModalEntityType } from "@/components/LinkAsModal";
import PostWithId from "@/features/post/components/PostWithId";
import ProductWithId from "@/features/product/components/ProductWithId";
import ProjectWithId from "@/features/project/components/ProjectWithId";
import useIsOpenModalShowDetail from "@/hooks/useIsOpenModalShowDetail";
import { useModalEntityListContext } from "@/contexts/ModalEntityListContext";
import useDevice from "@/hooks/useDevice";
import useLinkAsModal from "@/hooks/useLinkAsModal";
import IconChevronRight from "@/assets/imgs/svg/icon-chevron-right.svg";
import IconChevronLeft from "@/assets/imgs/svg/icon-chevron-left.svg";
import { FavoriteItemResponse } from "@/types/FavoriteItemResponse";
import { Response } from "@/contexts/ModalEntityListContext";

const isFavoriteItemResponse = (
  response: Response,
): response is FavoriteItemResponse => {
  return (response as FavoriteItemResponse).detailable_id !== undefined;
};

const scrollableTargetId = "ModalShowDetail";

const ModalShowDetail: FC = (): JSX.Element => {
  const { isPc } = useDevice();
  const { responses, setCurrent, nextModalEntity, prevModalEntity } =
    useModalEntityListContext();
  const { push } = useLinkAsModal();
  const router = useRouter();
  const isOpen = useIsOpenModalShowDetail();

  const { entityType, entityId, v_id } = router.query;
  const numberId = useMemo<number>(
    () => parseInt(entityId as string, 10),
    [entityId],
  );
  const numberVId = useMemo<number | undefined>(
    () => (v_id ? parseInt(v_id as string, 10) : undefined),
    [v_id],
  );
  const [content, setContent] = useState<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();

  const onClose = useCallback(() => {
    const query = Object.assign(router.query, {});
    delete query["entityId"];
    delete query["entityType"];
    delete query["v_id"];

    router.replace(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true },
    );
  }, [router]);

  const modalStyle = useMemo<ReactModal.Styles>(() => {
    return {
      overlay: {
        position: "fixed",
        inset: 0,
        backgroundColor: "rgba(0,0,0,0.7)",
        zIndex: 9999,
        overflow: "hidden",
      },
      content: {
        position: "relative",
        marginTop: 40,
        marginLeft: 0,
        marginRight: 0,
        height: "calc(100vh - 40px)",
        background: "#fff",
        overflowY: "scroll",
        borderRadius: "8px 8px 0 0",
      },
    };
  }, []);

  useEffect(() => {
    if (content) {
      content.scrollTo({ top: 0 });
      content.focus({ preventScroll: true });
    }
  }, [content, entityId, entityType]);

  useEffect(() => {
    setCurrent(numberId, entityType as ModalEntityType, numberVId);
  }, [numberId, entityType, setCurrent, numberVId]);

  // 左右のボタンで移動できるようにする
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowRight" && nextModalEntity) {
        push(nextModalEntity);
      }

      if (e.key === "ArrowLeft" && prevModalEntity) {
        push(prevModalEntity);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [nextModalEntity, prevModalEntity, push]);

  // リスト内のアイテムを見ているかどうか
  const isViewingListItem = useMemo<boolean>(() => {
    // responseの型がFavoriteItemResponseであれば、リスト内と判定する
    return responses.some((response) => {
      return isFavoriteItemResponse(response);
    });
  }, [responses]);

  // ページネーションを表示するかどうか
  const hasPaginationBtns = useMemo(() => {
    if (isPc) return true;

    // リスト内アイテムを閲覧中はページネーションボタンを表示しない
    if (isViewingListItem) return false;

    // モバイルの場合は、モーダルの中身がPostの場合のみページネーションボタンを表示する
    return entityType == ModalEntityType.Post;
  }, [isPc, entityType, isViewingListItem]);

  // 閉じている時でもレンダリングさせてしまうと、必ず最下層のモーダルになってしまうのでnullを返しておく
  if (!isOpen) return null;

  return (
    <ReactModal
      id={scrollableTargetId}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyle}
      onAfterOpen={({ contentEl }) => setContent(contentEl)}
      onAfterClose={() => setContent(undefined)}
      className={{
        base: "duration-300 opacity-0 transition-all ease-out hidden-scrollbar top-120",
        afterOpen: "opacity-100 !top-0",
        beforeClose: "",
      }}
      overlayClassName={{
        base: "duration-500 opacity-0 transition-all linear",
        afterOpen: "opacity-100",
        beforeClose: "",
      }}
      overlayElement={(props, contentElement) => (
        <div {...props}>{contentElement}</div>
      )}
      contentRef={(node) => {
        if (node) {
          contentRef.current = node;
        }
      }}
    >
      <header className="sticky top-0 flex justify-end h-0 z-50">
        <button className="p-8 text-primary" onClick={onClose}>
          <i className="icon-Close" />
        </button>
      </header>

      {entityType == ModalEntityType.Post && (
        <PostWithId scrollableTargetId={scrollableTargetId} id={numberId} />
      )}

      {entityType == ModalEntityType.Product && (
        <ProductWithId scrollableTargetId={scrollableTargetId} id={numberId} />
      )}

      {entityType == ModalEntityType.Project && (
        <ProjectWithId id={numberId} inModal containerRef={contentRef} />
      )}

      {hasPaginationBtns && (
        <>
          {prevModalEntity && (
            <div
              className={clsx(
                "fixed z-50",
                "laptop:bottom-auto laptop:top-1/2 laptop:left-0 laptop:transform laptop:-translate-y-1/2",
                "bottom-[16px] left-[8px]",
              )}
              aria-label="前へ戻る"
            >
              <LinkAsModal {...prevModalEntity}>
                <div
                  className={clsx(
                    "fill-black cursor-pointer p-8",
                    "laptop:bg-transparent laptop:shadow-transparent",
                    "bg-white rounded-full shadow-md stroke-black",
                  )}
                >
                  {isPc ? (
                    <svg
                      width="11"
                      height="20"
                      viewBox="0 0 11 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 1L1 10L10 19" />
                    </svg>
                  ) : (
                    <IconChevronLeft width={18} height={18} />
                  )}
                </div>
              </LinkAsModal>
            </div>
          )}

          {nextModalEntity && (
            <div
              className={clsx(
                "fixed z-50",
                "laptop:bottom-auto laptop:top-1/2 laptop:right-0 laptop:transform laptop:-translate-y-1/2",
                "bottom-[16px] right-[8px]",
              )}
              aria-label="次へ進む"
            >
              <LinkAsModal {...nextModalEntity}>
                <div
                  className={clsx(
                    "fill-black cursor-pointer p-8",
                    "laptop:bg-transparent laptop:shadow-transparent",
                    "bg-white rounded-full shadow-md stroke-black",
                  )}
                >
                  {isPc ? (
                    <svg
                      width="11"
                      height="20"
                      viewBox="0 0 11 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1 1L10 10L1 19" stroke="#08131A" />
                    </svg>
                  ) : (
                    <IconChevronRight width={18} height={18} />
                  )}
                </div>
              </LinkAsModal>
            </div>
          )}
        </>
      )}
    </ReactModal>
  );
};

export default ModalShowDetail;
