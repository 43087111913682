import ProjectShowTemplate from "@/features/project/components/ProjectShowTemplate";
import useProject from "@/features/project/hooks/useProject";
import { FC, RefObject } from "react";

interface Props {
  id: number;
  inModal?: boolean;
  containerRef?: RefObject<HTMLDivElement>;
}

const ProjectWithId: FC<Props> = ({ id, inModal = false, containerRef }) => {
  const { project } = useProject(id);

  if (!project) return null;

  return (
    <ProjectShowTemplate
      project={project}
      inModal={inModal}
      containerRef={containerRef}
    />
  );
};

export default ProjectWithId;
